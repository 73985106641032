import React, { Component } from 'react';
import { Row, Button, Card, Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Axios from "axios"
import "./buyDomain.css"
class HomePage extends Component {
    state = {
        width: 1920,
        height: 1080,
        domainOffer: false
    }
    scroll = () => {
        let element = document.getElementById("content")
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

    }
    componentDidMount() {
        Axios.get("https://hosting.breezelabs.co.uk/api/domains/sale/active").then(res => {
            this.setState({ domainOffer: res.data.active })
        })

        this.updateWindowDimensions();

        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.screen.availWidth, height: window.innerHeight });
    }
    handleHostingClick = (url) => {
        window.location.href = 'https://hosting.breezelabs.co.uk/' + url
    }


    render() {
        let width = "0"
        let height = "0"
        let noScreen = false
        if (this.state.width > 1280) {
            width = "1000"
            height = "500"
        } else if (this.state.width > 900) {
            width = "800"
            height = "400"
        } else if (this.state.width > 800) {
            width = "700"
            height = "350"
        } else if (this.state.width > 650) {
            width = "600"
            height = "300"
        } else {
            noScreen = true
        }
        return (
            <div style={{ minHeight: '100%' }} className="hosting-about" >




                {/* {!noScreen && <div style={{ margin: 'auto', height: 'auto', width: '100%', position: 'relative', textAlign: 'center', background: '#212529' }}>
                    <img alt='banner' style={{ maxWidth: '100%', maxHeight: '50%', minHeight: '350px' }} src={require('../pexels-manuel-geissinger-32529.jpg')}></img>


                    <div style={{ margin: 'auto', height: height + 'px', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: width + "px", borderRadius: '20px', borderStyle: 'solid', borderColor: '#343a40', backgroundColor: 'rgba(52,58,64,0.6)' }}>
                        <div style={{ margin: 'auto', height: (height - 60) + 'px', width: (width - 60) + "px", marginTop: "30px", borderRadius: '20px', borderStyle: 'solid', borderColor: '#343a40', backgroundColor: 'rgba(255,255,255,0.8)' }}>
                            <div style={{ position: 'absolute', top: '45%', width: width + "px", left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <h1 style={{ textAlign: 'center' }}>Create your website today</h1>

                                <h4 style={{ textAlign: 'center' }}>First month FREE on all hosting plans for first time customers!</h4>
                                <h4 style={{ textAlign: 'center' }}>Use code <b>BREEZE22</b> at checkout</h4>
                                <Row style={{ padding: '10px', width: '72%', margin: 'auto' }}>
                                    <Button className='call-to-action' style={{ width: '200px', margin: 'auto', backgroundColor: '#343a40' }} onClick={() => { this.handleHostingClick('create-account') }}>Create An Account</Button>
                                    <Button className='call-to-action' style={{ width: '200px', margin: 'auto', backgroundColor: '#343a40' }} onClick={() => { this.handleHostingClick('login') }}>Login</Button>
                                </Row>
                            </div>
                        </div>
                    </div>

                </div>} */}
                {/* {noScreen && <div>
                    <h1 style={{ textAlign: 'center', marginTop: "20px" }}>Create your website today</h1>
                    <h4 style={{ textAlign: 'center' }}>First month FREE on all hosting plans for first time customers!</h4>
                    <h4 style={{ textAlign: 'center' }}>Use code <b>BREEZE22</b> at checkout</h4>
                    <Row style={{ padding: '10px', width: '70%', margin: 'auto' }}>
                        <Button id="create-account-button" className='call-to-action' style={{ width: '200px', margin: 'auto', backgroundColor: '#343a40', marginTop: '5px' }} onClick={() => { this.handleHostingClick('create-account') }}>Create An Account</Button>
                        <Button className='call-to-action' style={{ width: '200px', margin: 'auto', backgroundColor: '#343a40', marginTop: '5px' }} onClick={() => { this.handleHostingClick('login') }}>Login</Button>
                    </Row></div>} */}
                <div style={{ paddingTop: '10px' }}>
                    {
                        this.state.domainOffer &&
                        <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
                        <Card style={{ textAlign: "center", maxWidth: "700px", padding: "15px", border: "2px solid #ff5722", borderRadius: "10px", margin: "auto",animation: "pulse 1.5s infinite", background: "rgba(52,201,235,1)" }}>
                            <h4 style={{ color: "#ff5722", fontSize: "1.5rem" }}>🚀<b> Flash Sale - Domains from £0.99!</b> 🚀</h4>
                        </Card>
                        </div>

                    }
                    <div className='fade-out-white'></div>
                    <div style={{ background: "white" }}>
                        <div style={{ background: "white", maxWidth: "1000px", margin: "auto" }}>

                            <Row>
                            {this.state.width > 800 &&
                                    <Col sm={5}>
                                        <div style={{  }}>
                                            <img alt='banner' style={{maxWidth: '100%', height: "auto" }} src={'/word-cloud.png'}></img>
                                        </div>

                                    </Col>}
                                <Col sm={this.state.width > 800 ? 7 : 12} style={{height: "100%", margin: "auto"}}>
                                    <div style={{ maxWidth: "100%", margin: "auto" }}>
                                        <h1 style={{ marginLeft: "35px" }}><b>The Web Hosting Service That Works For You!</b></h1>
                                        <ul>
                                            <li style={{ fontSize: "20px" }}>Fast, Reliable, Easy To Use!</li>
                                            <li style={{ fontSize: "20px" }}>Automated Setups, Backups, and Upgrades!</li>
                                            <li style={{ fontSize: "20px" }}>24/7 Customer Support!</li>
                                            <li style={{ fontSize: "20px" }}>Free Hosting For The First Month With Code BREEZE22!</li>
                                        </ul>
                                    </div>


                                </Col>
                          

                            </Row>
                        </div>
                    </div>

                    <div className='fade-in-white'></div>
                    <div style={{ maxWidth: '1280px', margin: 'auto', marginBottom: "20px" }}>
                        <Row style={{ padding: '10px', width: '72%', margin: 'auto' }}>
                            <Button id="create-account-button" className='call-to-action' style={{ width: '300px', margin: 'auto', marginTop: '10px', lineHeight: '50px', background: 'rgb(52, 58, 64)' }} onClick={() => { this.handleHostingClick('create-account') }}>
                                <p style={{ fontSize: '25px', margin: '0' }}>
                                    <b style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                        Create An Account
                                    </b>
                                </p>
                            </Button>
                            <Button className='call-to-action' style={{ width: '300px', margin: 'auto', marginTop: '10px', lineHeight: '50px', background: 'rgb(52, 58, 64)' }} onClick={() => { this.handleHostingClick('login') }}>
                                <p style={{ fontSize: '25px', margin: '0' }}>
                                    <b style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                        Login
                                    </b>
                                </p>
                            </Button>

                        </Row>
                        <Row style={{ padding: '10px' }}>
                            <h1 style={{ margin: 'auto' }}>
                                <u>Plans</u>
                            </h1>
                        </Row>
                        <Row style={{ maxWidth: '1000px', margin: 'auto' }}>
                            <Col style={{ paddingTop: '10px' }}>
                                <Card style={{ maxWidth: '300px', minWidth: '240px', margin: 'auto' }}>
                                    <Card.Header style={{ marginBottom: '10px', textAlign: 'center', background: 'rgb(52, 58, 64)', color: "white" }}>
                                        <b>Basic Hosting</b>
                                    </Card.Header>
                                    <ul style={{ height: '230px' }}>
                                        <li>Free SSL Certificate</li>
                                        <li>Free BreezeLabs Domain Name</li>
                                        <li>5GB SSD Storage</li>
                                        <li>Standard Support</li>
                                        <li>Unlimited Bandwidth</li>
                                        <li>1 Click Website Installers</li>
                                        <li>BreezeLabs Control Panel</li>
                                        <li>Easy Cancellations</li>
                                        <li>PHPMyAdmin</li>
                                        <li>FTP</li>
                                    </ul>
                                    <Card.Footer style={{ background: 'rgb(52, 58, 64)', color: "white" }}>
                                        <p style={{ textAlign: 'center' }}>
                                            <b>
                                                £1.00 / Month
                                            </b>
                                        </p>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col style={{ paddingTop: '10px' }}>
                                <Card style={{ maxWidth: '300px', minWidth: '240px', margin: 'auto' }}>
                                    <Card.Header style={{ marginBottom: '10px', textAlign: 'center', background: 'rgb(52, 58, 64)', color: "white" }}>
                                        <b>Standard Hosting</b>
                                    </Card.Header>
                                    <ul style={{ height: '230px' }}>
                                        <li>All Basic Features</li>
                                        <li>10GB SSD Storage</li>
                                        <li>24/7 Support</li>
                                        <li>Manual Backups</li>
                                        <li>1 Email Account (Coming Soon)</li>
                                    </ul>
                                    <Card.Footer style={{ background: 'rgb(52, 58, 64)', color: "white" }}>
                                        <p style={{ textAlign: 'center' }}>
                                            <b>
                                                £2.00 / Month
                                            </b>
                                        </p>
                                    </Card.Footer >
                                </Card>
                            </Col>
                            <Col style={{ paddingTop: '10px' }}>
                                <Card style={{ maxWidth: '300px', minWidth: '240px', margin: 'auto' }}>
                                    <Card.Header style={{ marginBottom: '10px', textAlign: 'center', background: 'rgb(52, 58, 64)', color: "white" }}>
                                        <b>Advanced Hosting</b>
                                    </Card.Header>
                                    <ul style={{ height: '230px' }}>
                                        <li>All Basic Features</li>
                                        <li>20GB SSD Storage</li>
                                        <li>24/7 Priority Support</li>
                                        <li>Manual Backups</li>
                                        <li>Weekly Automated Backups</li>
                                        <li>10 Email Accounts (Coming Soon)</li>
                                    </ul>
                                    <Card.Footer style={{ background: 'rgb(52, 58, 64)', color: "white" }}>
                                        <p style={{ textAlign: 'center' }}>
                                            <b>
                                                £4.00 / Month
                                            </b>
                                        </p>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <p style={{ margin: 'auto', width: '75%', textAlign: 'center', paddingBottom: '40px', maxWidth: '1280px', paddingTop: "20px" }}>
                                Our 'BreezeLabs Control Panel' is purposely built to easily enable you to create as many websites as you want within one simple account.
                                Each hosting plan has its own seperate page for you to configure and manage your plan as needed. Aswell as this, all of your payments are easily managed within their respective plan page.
                            </p>
                        </Row>
                    </div>
                    <div className='hosting-grade-in' />
                    {/* <hr style={{width: '85%'}}/> */}
                    <div className='hosting-grade-out' style={{ paddingBottom: '10px' }}>
                        <div style={{ margin: 'auto', width: '75%', textAlign: 'center', paddingBottom: '40px', maxWidth: '1280px' }}>
                            <Row style={{ padding: '10px' }}>
                                <h1 style={{ margin: 'auto', textAlign: 'center' }}>
                                    <u>Features</u>
                                </h1>
                            </Row>
                            <Row>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u>PHPMyAdmin</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>
                                        <p style={{ margin: 'auto', textAlign: 'center' }}>
                                            Having control of your websites data is essential for running a website. This is why we give everyone access to manage, maintain and modify there MySQL database from within PHPMyAdmin. This enables website owners to do everything from upload or download their database to
                                            searching and modifying their database on the fly.
                                        </p>
                                    </Row>
                                </Col>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u>FTP</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>

                                        FTP (File Transfer Protocol) is your website's best friend, enabling you to effortlessly upload, download, and manage files on our servers. This user-friendly tool simplifies website content management, giving you full control over your data and ensuring a seamless online presence.
                                    </Row>
                                </Col>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u>No Hidden Fees</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>
                                        <p style={{ margin: 'auto', textAlign: 'center' }}>
                                            We know how annoying it can be to get setup with a hosting provider and then have them bombard you with hidden fees and increase your monthly prices. Thats why we will never increase your plan prices. If
                                            we ever need to increase our prices, yours will remain the same aslong as your plan stays active. To go along with this, we have no hidden fees and will never request additional costs aslong as you stay within your plans limits.
                                        </p>
                                    </Row>
                                </Col>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u>Based in the UK with servers in London</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>
                                        <p style={{ margin: 'auto', textAlign: 'center' }}>
                                            Our London servers ensure you get competitive load times throughout the UK
                                            aswell as around the world.
                                            We are also based in the UK, so you can be assured that no matter
                                            what time of day you can get the best support possible.
                                        </p>
                                    </Row>
                                </Col>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u>Free SSL Certificate</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>
                                        <p style={{ margin: 'auto', textAlign: 'center' }}>
                                            We know how important security is, thats why we give every website you create a free SSL
                                            Certificate. This keeps all the data transfered between the server and your viewers encrypted
                                            to keep both you and your viewers information safe. When anyone visits your site they will see
                                            that trusted "https://" in the url giving them the confidence they need to use your website.
                                        </p>
                                    </Row>
                                </Col>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u>Free BreezeLabs Domain Name</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>
                                        <p style={{ margin: 'auto', textAlign: 'center' }}>
                                            Create your BreezeLabsHosting domain name today for free. With our simple 2 click process you can create your domain and add it to your website, the longest part of this process will be deciding what you want your domain name to be. Use this as a placeholder until you buy a domain name or
                                            as a permanent domain name, its all up to you.
                                        </p>
                                    </Row>
                                </Col>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u> BreezeLabs Hosting Panel</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>
                                        <p style={{ margin: 'auto', textAlign: 'center' }}>
                                            Our custom 'Breezelabs hosting panel' is built from the ground up to provide
                                            the best experience for everyone. Whether you're a beginer or an expert,
                                            our tools easily enable you to create the website of your dreams. We work tirelessly
                                            to ensure the best features are added to the control panel regularly.
                                        </p>
                                    </Row>
                                </Col>

                            </Row>
                        </div>
                    </div>

                    {/* <hr style={{width: '85%'}}/> */}
                    <div style={{ maxWidth: '1280px', margin: 'auto' }}>
                        <Row style={{ padding: '10px' }}>
                            <h1 style={{ margin: 'auto', textAlign: 'center' }}>
                                <u>Websites Supported</u>
                            </h1>
                        </Row>
                        <Row style={{ padding: '10px' }}>
                            <p style={{ margin: 'auto', textAlign: 'center' }}>
                                Our BreezeLabs Control Panel includes 1 click installations for the following types of websites:
                            </p>
                        </Row>
                        <div style={{ margin: 'auto', width: '100%', textAlign: 'center', paddingBottom: '10px' }}>
                            <Row style={{ padding: '10px' }}>
                                <Col style={{ width: '100%' }}>
                                    <div className="flip-card" style={{ margin: 'auto', paddingRight: "10px", paddingLeft: "10px", paddingTop: "10px" }}>
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="Wordpress Logo" src={require('../wordpress.jpg')} style={{ width: "260px", height: "260px" }} />
                                                <h2 style={{ padding: '10px' }}>WordPress</h2>
                                                <FontAwesomeIcon size='1x' icon={faInfoCircle} style={{ color: 'rgb(0, 123, 255)' }} />

                                            </div>
                                            <div className="flip-card-back">
                                                <p style={{ margin: 'auto', textAlign: 'center', padding: '5px' }}>
                                                    WordPress is one of the worlds most powerful and popular content management systems which allows you to create anything from blogs to
                                                    personal websites, online stores and so much more.
                                                </p>
                                                <p style={{ margin: 'auto', textAlign: 'center' }}>
                                                    You can choose from WordPress's library of thousands of themes and plugins,
                                                    both free and payed, to make the website thats perfect for you.
                                                    WordPress' is open source with a huge community of developers and
                                                    users. WordPress is a great choice for both new and
                                                    experienced website creators.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col >
                                    <div className="flip-card" style={{ margin: 'auto', paddingRight: "10px", paddingLeft: "10px", paddingTop: "10px" }}>
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt='Dokuwiki logo' style={{ width: '260px' }} src={require('../Dokuwiki_logo.png')}></img>
                                                <h2 style={{ padding: '10px' }}>DokuWiki</h2>
                                                <FontAwesomeIcon size='1x' icon={faInfoCircle} style={{ color: 'rgb(0, 123, 255)' }} />
                                            </div>
                                            <div className="flip-card-back">
                                                <p style={{ margin: 'auto', textAlign: 'center' }}>
                                                    DokuWiki is a simple to use and highly versatile open source wiki software. It is loved by users worldwide for its ease-of-use and easy to read syntax.
                                                    The simplicity of everything, from user management to creating pages, makes it a perfect choice to create your wiki. WikiDoku has a vibrant community with thousands
                                                    of plugins available to allow for a broad range of use cases beyond the traditional wiki.
                                                </p>
                                                <p style={{ margin: 'auto', textAlign: 'center' }}>
                                                    With the countless use cases of the WikiDoku website, you can create anything from a publicly viewable blog to a private business knowledge base.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {/* <hr style={{width: '85%'}}/> */}
                    <div className='hosting-grade-in' />
                    <div className='hosting-grade-out' style={{ paddingBottom: '10px' }}>
                        <div style={{ margin: 'auto', width: '75%', textAlign: 'center', paddingBottom: '40px', maxWidth: '1280px' }}>
                            <Row style={{ padding: '10px' }}>
                                <h1 style={{ margin: 'auto', textAlign: 'center' }}>
                                    <u>Domains</u>
                                </h1>
                            </Row>
                            <Row>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u>Find the Perfect Domain Name</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>
                                        <p style={{ margin: 'auto', textAlign: 'center' }}>
                                            Your website's domain name is the online identity of your business or project. Find the perfect domain name for your brand from a wide range of options available. We offer various TLDs (Top-Level Domains) like .com, .net, .org, .co.uk, and more. Make a lasting impression with a memorable domain name.
                                        </p>
                                    </Row>
                                </Col>
                                <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                                    <Row style={{ padding: '10px' }}>
                                        <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                                            <u>Secure and Reliable Domain Registration</u>
                                        </h4>
                                    </Row>
                                    <Row style={{ padding: '10px' }}>
                                        <p style={{ margin: 'auto', textAlign: 'center' }}>
                                            Trust your domain registration to us. We provide secure and reliable domain services, ensuring your online presence is always up and running. With our user-friendly domain management tools, you can easily update DNS settings, manage renewals, and safeguard your domain with privacy protection.
                                        </p>
                                    </Row>
                                </Col>
                                {/* <Col sm="4" style={{ margin: "auto", minWidth: "300px" }}>
                <Row style={{ padding: '10px' }}>
                    <h4 style={{ margin: 'auto', textAlign: 'center' }}>
                        <u>Domain Transfer Made Easy</u>
                    </h4>
                </Row>
                <Row style={{ padding: '10px' }}>
                    <p style={{ margin: 'auto', textAlign: 'center' }}>
                        Thinking of moving your domain to BreezeLabs? We make domain transfer hassle-free. Join our platform and enjoy seamless domain transfer with excellent customer support. Our team is here to assist you every step of the way in making sure your domain is transferred smoothly and efficiently.
                    </p>
                </Row>
            </Col> */}
                            </Row>
                            <Row style={{ padding: '10px' }}>
                                <Button className='call-to-action' style={{ width: '200px', margin: 'auto', backgroundColor: '#343a40' }} onClick={() => { this.handleHostingClick('domains') }}>Find Your Domain</Button>

                            </Row>
                        </div>
                    </div>
                    <div >
                        <div style={{ margin: 'auto', width: '75%', textAlign: 'center', paddingBottom: '10px', maxWidth: '1280px' }} id="partner">
                            <Row style={{ padding: '10px' }}>
                                <h1 style={{ margin: 'auto', textAlign: 'center' }}>
                                    <u>Become a website developer partner</u>
                                </h1>
                            </Row>
                            <Row style={{ padding: '10px' }}>
                                <p style={{ margin: 'auto', textAlign: 'center' }}>Our partner programme gives our partners exclusive hosting discounts for their customers, not only making their customers happy but incentivising new customers for that partner. You wont be under strict conditions or policies,
                                    you only need to keep your customers happy creating amazing websites and acting with good standards. Each of your customers will receive £10 credit on BreezeLabs through a coupon code we will give to you. We also hold regular competions and showcases for our partners.
                                    Whats not to like? Apply today!</p>
                            </Row>
                            <Row style={{ padding: '10px' }}>

                                <Button className='call-to-action' style={{ width: '200px', margin: 'auto', backgroundColor: '#343a40' }} as={Link} to="/hosting/partner">Find Out More</Button>

                            </Row>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePage;
